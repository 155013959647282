/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import 'bootstrap/dist/css/bootstrap';
// General theme contents
@import 'partials/variables';
@import 'partials/general';
@import 'partials/generic';
@import 'partials/mixins/function';

// icon
// @import 'partials/font/next-icon';
// @import 'partials/font/feather';
// @import 'partials/font/fontawesome';

// important Element
@import 'partials/menu/menu-lite';
@import 'partials/widget/widget';

// Theme Element
@import 'partials/theme-elements/form';
@import 'partials/theme-elements/radiobox-checkbox';
@import 'partials/theme-elements/labels-badges';
@import 'partials/theme-elements/data-tables';
@import 'partials/theme-elements/authentication';
@import 'partials/theme-elements/button';
@import 'partials/theme-elements/breadcrumb-pagination';
@import 'partials/theme-elements/progress';
@import 'partials/theme-elements/nav';

// Other
@import 'partials/other/chat';

@import 'partials/third-party/third-party';
.custom-input-focus:focus {
  border-width: 1px;          /* Set the border thickness */
  border-color: #0dcaf0;      /* Change border color */
  box-shadow: none;           /* Remove box-shadow to make focus less heavy */
}
.form-group-spacing {
  margin-bottom: 12px; /* Adjusts space between form groups */
}

.form-group-spacing label {
  margin-bottom: 0; /* Removes space between label and input */
}