/**  =====================
      Data Tables css start
==========================  **/
.table {
  color: #888;
  td,
  th {
    border-top: 1px solid $theme-border;
    white-space: nowrap;
    padding: 1.05rem 0.75rem;
  }

  thead th {
    border-bottom: 1px solid $theme-border;
  }

  tbody + tbody {
    border-top: 2px solid $theme-border;
  }

  &.table-sm {
    th,
    td {
      padding: 0.35rem;
    }
  }
}
/* Border versions */

.table-bordered {
  border: 1px solid $theme-border;

  td,
  th {
    border: 1px solid $theme-border;
  }
}
/* Zebra-striping */

.table-striped {
  tbody tr:nth-of-type(2n + 1) {
    background-color: transparentize($primary-color, 0.95);
  }
}
/* Hover effect */

.table-hover {
  tbody tr {
    &:hover {
      background-color: transparentize($primary-color, 0.95);
    }
  }
}

/**====== Data Tables css end ======**/
