/**  =====================
      Radio & Checked css start
==========================  **/
.radio,
.checkbox {
  .form-check {
    padding-left: 0;
    margin-bottom: 6px;

    input[type='radio'] + .form-check-label:after {
      top: 5px;
    }
  }
}
