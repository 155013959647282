/**  =====================
      Custom css start
==========================  **/
body {
  font-family: $theme-font-family;
  font-size: $theme-font-size;
  color: $theme-font-color;
  font-weight: 400;
  background: $theme-background;
  position: relative;
}

* {
  &:focus {
    outline: none;
  }
}

a {
  &:hover {
    outline: none;
  }

  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-heading-color;
  font-weight: 400;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
}

strong {
  font-weight: 400;
}

label {
  margin-bottom: 0.5rem;
}

.img-radius {
  border-radius: 50%;
}

.page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;

  > .breadcrumb-item {
    a {
      color: #888;
    }

    &:last-child a {
      color: #111;
      font-weight: 600;
    }
  }
}

.theme-bg {
  background: $theme-color;
}

.theme-bg2 {
  background: $theme-color2;
}

/* ==========  card css start  =========== */

.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

p.text-muted {
  font-size: 13px;
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  --bs-card-title-color: #111;
  .card-header {
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 25px;
    position: relative;

    h5 {
      margin-bottom: 0;
      color: #000;
      font-size: 17px;
      font-weight: 400;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative;

      &:after {
        content: '';
        background-color: $primary-color;
        position: absolute;
        left: -25px;
        top: 0;
        width: 4px;
        height: 20px;
      }
    }

    &.borderless {
      border-bottom: none;

      h5:after {
        display: none;
      }
    }
    .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-flex;
      float: inline-end;
      padding: 0;
      position: absolute;

      @media only screen and (max-width: 575px) {
        display: none;
      }

      .dropdown-menu {
        margin-top: 0;

        li {
          a {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }

      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888;

        i {
          margin-right: 0;
        }

        &:after {
          display: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .card-footer {
    border-top: 1px solid #f1f1f1;
    background: transparent;
    padding: 25px;
  }

  .card-block,
  .card-body {
    padding: 30px 25px;
  }

  &.card-load {
    position: relative;
    overflow: hidden;

    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(256, 256, 256, 0.7);
      z-index: 999;

      i {
        margin: 0 auto;
        color: $primary-color;
        font-size: 24px;
        align-items: center;
        display: flex;
      }
    }
  }

  &.full-card {
    z-index: 99999;
    border-radius: 0;
  }
}

/* ==========  scrollbar End  =========== */

.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;

  &.show {
    &:before {
      content: '\63';
      font-family: 'pct';
      position: absolute;
      left: 115px;
      top: -5px;
      z-index: 1001;
      font-size: 40px;
      line-height: 0;
      color: #fff;
      text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
    }

    &.dropdown-menu-right {
      &:before {
        left: auto;
        right: 5px;
      }
    }
  }

  &[x-placement='top-start'] {
    margin-bottom: 15px;
    margin-top: 0;

    &.show {
      &:before {
        content: '\64';
        bottom: -5px;
        top: auto;
        text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
      }
    }
  }

  > li {
    padding-left: 15px;
    padding-right: 15px;

    > a {
      padding: 5px;
      color: $theme-font-color;

      i {
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: transparentize($primary-color, 0.9);

      > a {
        background: transparent;
      }
    }
  }
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

/* ========================================================
 ===============     document      ======================
 ========================================================

/* Code snippets */

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media only screen and (max-height: 575px) {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;

  @media only screen and (max-height: 575px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight {
  pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
  }

  pre code {
    font-size: inherit;
    color: #333;
  }
}

/* ================================    Blockquote Start  ===================== */

.blockquote {
  border-left: 0.25rem solid $theme-border;
  padding: 0.5rem 1rem;

  &.text-right {
    border-left: none;
    border-right: 0.25rem solid $theme-border;
  }
}

/* ================================    Blockquote End  ===================== */

.btn-link {
  text-decoration: none;
}

.table {
  --bs-table-striped-bg: rgba(4, 169, 245, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background: rgba(4, 169, 245, 0.05);

  > * {
    --bs-table-color-state: #212529;
  }
}

.table-hover > tbody > tr:hover > * {
  --bs-table-bg-state: rgba(4, 169, 245, 0.05);
}

.btn-check:checked + .btn:not([class*='btn-']),
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  --bs-btn-active-bg: #04a9f5;
  --bs-btn-active-border-color: #04a9f5;
}

.recent-users {
  tr {
    td {
      &:first-child {
        width: 60px;
        padding-left: 20px;
        padding-right: 0;
      }

      &:last-child {
        text-align: center;
      }
    }
  }
}

.blockquote-footer {
  margin-top: 0;
}

.btn.text-secondary.btn-link {
  &:active,
  &.active,
  &.show {
    color: #fff !important;
  }
}
