@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'ngx-perfect-scrollbar';
@import 'react-bootstrap/react-bootstrap';

.pcoded-header .main-search .input-group-text {
  line-height: 1;
}

.text-muted {
  color: #6c757d !important;
}

.card-event {
  .label {
    border-radius: 15px;
    margin: 0;
    padding: 5px 16px;
  }

  i {
    position: absolute;
    bottom: 36px;
    right: 27px;
  }
}

.page-link {
  font-size: 0.9rem !important;
}
